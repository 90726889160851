import { DEFAULT_DISPLAY_OPTIONS, DisplayOptions, MonthLayout, TaskView, TaskViewEnum, isMonthLayout } from '@/types/display'
import { getForageItem } from '@planda/design-system'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import localforage from 'localforage'
import { isBoolean, isString } from 'lodash'

interface State {
    taskDisplayOptions: DisplayOptions
    monthLayout: MonthLayout
    listPageView: TaskView
    monthNotesIsExpanded: boolean
}

const initialState: State = {
    taskDisplayOptions: DEFAULT_DISPLAY_OPTIONS,
    monthLayout: 'full',
    listPageView: 'list',
    monthNotesIsExpanded: false,
}

const MONTH_LAYOUT_FORAGE_KEY = 'display-month-layout'
const LIST_PAGE_VIEW_FORAGE_KEY = 'list-tasks-display-view'
const MONTH_NOTES_EXPANDED_FORAGE_KEY = 'month-notes-expanded'

const SIMPLE_CONFIG = {
    monthLayout: { key: MONTH_LAYOUT_FORAGE_KEY, isValid: isMonthLayout },
    listPageView: { key: LIST_PAGE_VIEW_FORAGE_KEY, isValid: (x: unknown): x is TaskView => TaskViewEnum.safeParse(x).success },
    monthNotesIsExpanded: { key: MONTH_NOTES_EXPANDED_FORAGE_KEY, isValid: isBoolean },
}

type SimpleState = Omit<State, 'taskDisplayOptions'>
export const loadPreferencesState = createAsyncThunk('yourSlice/loadTemporaryState', async (): Promise<State> => {
    const newDisplayOptions: Record<string, any> = {}
    const taskDisplayPromises = Object.entries(DEFAULT_DISPLAY_OPTIONS).map(async ([key, value]) => {
        const isValid = typeof value === 'boolean' ? isBoolean : isString
        const storedValue = await getForageItem('task-display--' + key, isValid, value)
        newDisplayOptions[key] = storedValue
    })

    // const monthLayout = await getForageItem(MONTH_LAYOUT_FORAGE_KEY, isMonthLayout, initialState.monthLayout)
    // const listPageView = await getForageItem(
    //     LIST_PAGE_VIEW_FORAGE_KEY,
    //     (x): x is TaskView => TaskViewEnum.safeParse(x).success,
    //     initialState.listPageView
    // )

    const obj: Partial<SimpleState> = {}
    const promises = Object.keys(SIMPLE_CONFIG).map(async (k) => {
        const key = k as keyof typeof SIMPLE_CONFIG
        const { key: storageKey, isValid } = SIMPLE_CONFIG[key]
        // @ts-ignore
        obj[key] = await getForageItem(storageKey, isValid, initialState[key])
    })
    await Promise.all(promises)

    await Promise.all(taskDisplayPromises)
    return { taskDisplayOptions: newDisplayOptions as DisplayOptions, ...(obj as SimpleState) }
})

const displayPreferencesSlice = createSlice({
    name: 'display-preferences',
    initialState,
    reducers: {
        editDisplayOptions: (state, action: PayloadAction<{ field: keyof DisplayOptions; value: any }>) => {
            const { field, value } = action.payload
            // @ts-expect-error
            state.taskDisplayOptions[field] = value
            localforage.setItem('task-display--' + field, value)
        },
        setSimpleDisplayOption: <K extends keyof SimpleState,>(state: SimpleState, action: PayloadAction<{ key: K; value: SimpleState[K] }>) => {
            const { key, value } = action.payload
            if (!(key in SIMPLE_CONFIG)) return
            state[key] = value
            localforage.setItem(SIMPLE_CONFIG[key].key, value)
        },
        setMonthLayout: (state, action: PayloadAction<MonthLayout>) => {
            state.monthLayout = action.payload
            localforage.setItem(MONTH_LAYOUT_FORAGE_KEY, action.payload)
        },
        setListPageView: (state, action: PayloadAction<TaskView>) => {
            state.listPageView = action.payload
            localforage.setItem(LIST_PAGE_VIEW_FORAGE_KEY, action.payload)
        },
        setMonthNotesIsExpanded: (state, action: PayloadAction<boolean>) => {
            state.monthNotesIsExpanded = action.payload
            localforage.setItem(MONTH_NOTES_EXPANDED_FORAGE_KEY, action.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadPreferencesState.fulfilled, (state, action: PayloadAction<State>) => {
            return action.payload
            // for (const key in action.payload) {
            //     // @ts-expect-error
            //     state[key] = action.payload[key]
            // }
        })
    },
})

export const { editDisplayOptions, setMonthLayout, setListPageView, setMonthNotesIsExpanded, setSimpleDisplayOption } = displayPreferencesSlice.actions
export default displayPreferencesSlice.reducer
