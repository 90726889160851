import { LessonId } from '@/features/game/levels/config/lessons/lessons'
import { PartialAllItemProperties } from '@/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'

export type Panel = 'timegrid-day' | 'daygrid-day' | 'levels' | 'past-lessons' | 'challenges'
export type WhichPanel = Panel | null
export type TutorialArrow = string
interface TaskSelectionMode {
    on: boolean
    selected: string[]
    maxSelect?: number
    minSelect?: number
    action?: 'task'
}

interface State {
    listTab?: 'daily' | 'main' | 'completed' | 'ideas'
    panel: WhichPanel
    tutorialArrow?: TutorialArrow
    openForm: boolean
    defaultFormItems?: PartialAllItemProperties
    taskSelectionMode: TaskSelectionMode
    openLessonCardId?: LessonId
}

const initialState: State = {
    panel: null,
    openForm: false,
    taskSelectionMode: { on: false, selected: [] },
    // inSelectTasksMode: false,
    // selectedTasks: [],
}

const temporaryStateSlice = createSlice({
    name: 'temporary-state',
    initialState,
    reducers: {
        setTemporaryState: (state, action: PayloadAction<Partial<State>>) => {
            const updates = action.payload
            Object.entries(updates).forEach(([key, value]) => {
                // @ts-expect-error
                state[key] = value
            })
        },
        setPanel: (state, action: PayloadAction<WhichPanel>) => {
            state.panel = action.payload
        },
        togglePanel: (state, action: PayloadAction<Panel>) => {
            state.panel = state.panel === action.payload ? null : action.payload
        },
        openItemForm(state, action: PayloadAction<PartialAllItemProperties | undefined>) {
            action.payload && (state.defaultFormItems = action.payload)
            state.openForm = true
        },
        setOpenItemForm: (state, action: PayloadAction<boolean>) => {
            state.openForm = action.payload
        },
        setTaskIsSelected: (state, action: PayloadAction<{ id: string; checked: boolean }>) => {
            const { id, checked } = action.payload
            const taskSelectionMode = state.taskSelectionMode
            if (!checked) {
                taskSelectionMode.selected = taskSelectionMode.selected.filter((taskId) => taskId !== id)
            } else {
                if (taskSelectionMode.maxSelect !== undefined && taskSelectionMode.selected.length >= taskSelectionMode.maxSelect) {
                    return
                }
                taskSelectionMode.selected.push(id)
                taskSelectionMode.selected = uniq(taskSelectionMode.selected)
            }
        },
        setTaskSelectionMode: (state, action: PayloadAction<Partial<TaskSelectionMode>>) => {
            const updates = action.payload
            Object.entries(updates).forEach(([key, value]) => {
                // @ts-expect-error
                state.taskSelectionMode[key] = value
            })
        },
        setTutorialArrow: (state, action: PayloadAction<TutorialArrow | undefined>) => {
            state.tutorialArrow = action.payload
        },
        setOpenLessonCardId: (state, action: PayloadAction<LessonId | null>) => {
            state.openLessonCardId = action.payload ?? undefined
        },
    },
})

export const {
    setTemporaryState,
    setPanel,
    togglePanel,
    openItemForm,
    setOpenItemForm,
    setTaskIsSelected,
    setTaskSelectionMode,
    setTutorialArrow,
    setOpenLessonCardId,
} = temporaryStateSlice.actions
export default temporaryStateSlice.reducer
